html, body {
  margin: 0;
  height: 100%;
  width: 100%;
}

body {
  font-size: 18px;
  background-color: #e7e7e7;
}

.main {
    height: 520px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 15px 50px -10px rgba(0, 0, 0, 0.25);
}

.sidebar, .content {
    height: 520px;
    overflow-y: scroll;
}

.sidebar ul li {
    border: 0;
    padding: 20px;
    border-radius: 0!important;
    cursor: pointer;
}

.sidebar ul li:hover {
  background: #F5F5F5;
}

.sidebar ul li span {
    position: relative;
    display: inline-block;
    top: -2px;
    color: #fff;
    height: 27px;
    margin-right: 5px;
    width: 27px;
    text-align: center;
    padding: 2px 0;
    background: #424242;
    font-size: 14px;
    border-radius: 50%;
}

.sidebar {
    border-right: solid 1px rgba(0, 0, 0, 0.10);
    box-shadow: 6px 0 25px -13px rgba(0, 0, 0, 0.25);
}

.content h3 {
    background: #F5F5F5;
    color: #000;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    padding: 20px 0;
}

.content .surah li {
    border: none;
    text-align: right;
    font-weight: 700;
}

.content .surah li span {
    display: inline-block;
    background: #424242;
    color: #fff;
    height: 25px;
    width: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 13px;
    border-radius: 50%;
    margin-left: 10px;
}

.content .surahEn {
  margin-top: 30px;
}

.content .surahEn li {
    border: none;
}

.content .surahEn li span {
    display: inline-block;
    background: #424242;
    color: #fff;
    height: 25px;
    width: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 13px;
    border-radius: 50%;
    margin-right: 10px;
}

.title {
    background: #000;
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    padding: 20px 0;
}

.footer a {
    display: block;
    background: #424242;
    color: #fff;
    text-align: center;
    padding: 15px 0;
}